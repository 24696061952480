import * as firebase from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
	apiKey: "AIzaSyCi6iw63WPyfYwSAVBQTLQr-m5vnABLqQI",
	authDomain: "appverif-da74b.firebaseapp.com",
	projectId: "appverif-da74b",
	storageBucket: "appverif-da74b.firebasestorage.app",
	messagingSenderId: "647247542354",
	appId: "1:647247542354:web:54e0de9a44c8d82fc3478b",
	measurementId: "G-2NE6HWJJ4Y",
};

const firebaseCloudMessaging = {
	tokenInlocalforage: async () => {
		return localStorage.getItem("fcm_token");
	},
	init: async function () {
		if (!firebase.getApps().length) {
			firebase.initializeApp(firebaseConfig);
			try {
				const messaging = getMessaging();
				const tokenInLocalForage = await this.tokenInlocalforage();
				if (tokenInLocalForage !== null) {
					return tokenInLocalForage;
				}
				const status = await Notification.requestPermission();
				if (status && status === "granted") {
					const fcm_token = await getToken(messaging, {
						vapidKey:
							"BLHq5I3X0W-cSS-fcICG_Jdibk9umWhovhIRzF5GAP3TG9y_7gmzjAkA2iNdooH1kSGniSFetr7IOE-qk2okymM",
					});

					if (fcm_token) {
						localStorage.setItem("fcm_token", fcm_token);
						return fcm_token;
					}
				}
			} catch (error) {
				console.error(error);
				return null;
			}
		} else {
			try {
				const messaging = getMessaging();
				const tokenInLocalForage = await this.tokenInlocalforage();
				if (tokenInLocalForage !== null) {
					return tokenInLocalForage;
				}
				const status = await Notification.requestPermission();
				if (status && status === "granted") {
					const fcm_token = await getToken(messaging, {
						vapidKey:
							"BLHq5I3X0W-cSS-fcICG_Jdibk9umWhovhIRzF5GAP3TG9y_7gmzjAkA2iNdooH1kSGniSFetr7IOE-qk2okymM",
					});

					if (fcm_token) {
						localStorage.setItem("fcm_token", fcm_token);
						return fcm_token;
					}
				}
			} catch (error) {
				console.error(error);
				return null;
			}
		}
	},
	getMessage: async function () {
		if (firebase.getApps().length > 0) {
			try {
				const messaging = getMessaging();
				onMessage(messaging, (payload) => {
					console.log("Message Received", payload);
				});
			} catch (error) {}
		}
	},
};
export { firebaseCloudMessaging };
