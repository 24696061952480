import { Toaster } from "react-hot-toast";
import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { I18nProvider } from "./context/i18n";
import { UserDataProvider } from "./context/userDataContext";
import { Spin } from "antd";

const App = lazy(() => import("./App"));
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
      <UserDataProvider>
        <I18nProvider>
          <Toaster />
          <Suspense
          fallback={
            <div className="spin-container">
              <Spin size="large" />
            </div>
          }
        >
          <App />
        </Suspense>
        </I18nProvider>
      </UserDataProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
